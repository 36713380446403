






import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import DPlayer from 'dplayer'
import Hls from 'hls.js'

import { MediaResource } from '@/store/types'
import MasterLessonsModule from '@/store/modules/master/lessons'

@Component
export default class DPlayerVideoPlayer extends Vue {
  @Ref() dplayer!: HTMLDivElement

  @Prop({ required: true })
  private video!: MediaResource

  @Prop({ default: null })
  private saveTimecode!: number | null

  @Prop({ required: true })
  private lessonID!: number

  @Prop({ required: true })
  private masterGroupID!: number

  private interval: number | null = null
  private player: any = null
  private hls: Hls | null = null
  private config: any = {
    container: this.dplayer,
    theme: '#ffa552',
    video: {
      defaultQuality: 0,
      quality: this.video.formats && this.video.formats.length > 0 ?
        [
          ...this.video.formats.map((format, index) => ({
            index,
            name: format.label,
            url: format.url,
          })),
        ].reverse() : undefined,
      url: this.video.url,
    },
  }

  private method = this.saveTimeLesson.bind(this, true)
  private checkIsIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  private mounted() {
    window.addEventListener('beforeunload', this.method)
    this.config.container = this.dplayer
    if (this.video.hls && Hls.isSupported()) {
      this.hlsInit()
    } else {
      this.dplayerInit(this.config)
    }
  }

  private beforeDestroy() {
    this.saveTimeLesson()
    this.clearInterval()
    window.removeEventListener('beforeunload', this.method)
    this.player?.destroy()
  }

  private dplayerInit(config: any) {
    this.player = new DPlayer(config)

    this.player.on('canplay', () => {
      if (this.saveTimecode !== null && this.checkIsIOS) {
        this.player.video.currentTime = this.saveTimecode
      }
      this.$emit('videoInitialized', this.player.video)
    })
    this.player.on('loadedmetadata', () => {
      if (this.saveTimecode !== null && !this.checkIsIOS) {
        this.player.video.currentTime = this.saveTimecode
      }
      this.$emit('readymetadata', this.player.video.duration)
    })
    this.player.on('pause', () => {
      this.saveTimeLesson()
      this.clearInterval()
    })
    this.player.on('ended', () => {
      this.clearInterval()
      MasterLessonsModule.saveTimecodeLesson({
        lessonID: this.lessonID,
        masterGroupID: this.masterGroupID,
        seconds: 0,
        unload: false,
      })
      localStorage.removeItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`)
    })
    this.player.on('play', () => {
      this.interval = setInterval(this.saveTimeInLocalStorage, 5000)
    })
    if (this.video.hls && Hls.isSupported()) {
      this.player.on('quality_start', (e: any) => {
        if (e.index === 0) {
          window.hls.currentLevel = -1
        } else {
          window.hls.levels.forEach((level: any, levelIndex: number) => {
            if (level.height === e.name) {
              window.hls.currentLevel = levelIndex
            }
          })
        }
      })
    }
  }

  private hlsInit() {
    this.hls = new Hls()
    this.hls.loadSource(this.video.hls as string)
    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      const availableQualities = (this.hls as Hls).levels.map(l => l.height)
      availableQualities.unshift(0)

      this.config = {
        container: this.dplayer,
        theme: '#ffa552',
        video: {
          customType: {
            customHls: this.customHls,
          },
          defaultQuality: 0,
          //quality: availableQualities.map((q, index) => ({ index, name: index === 0 ? 'Авто' : q })),
          type: 'customHls',
          url: this.video.hls,
        },
      }

      ;(this.hls as Hls).on(Hls.Events.LEVEL_SWITCHED, (event: any, data: any) => {
        const div = document.querySelector('.dplayer-quality-item[data-index="0"]')
        if (div) {
          div.innerHTML = (this.hls as Hls).autoLevelEnabled ? `Авто (${(this.hls as Hls).levels[data.level].height}p)` : 'Авто'
        }
      })

      this.dplayerInit(this.config)
      window.hls = this.hls
    })
  }

  private customHls(video: HTMLVideoElement) {
    (this.hls as Hls).attachMedia(video)
  }

  // вызывается при паузе на плеере или событии beforeUnload
  private saveTimeLesson(unload = false) {
    const seconds = Math.floor(this.player.video.currentTime)
    if (this.saveTimecode !== seconds && Math.floor(this.player.video.duration) !== seconds) {
      MasterLessonsModule.saveTimecodeLesson({
        lessonID: this.lessonID,
        masterGroupID: this.masterGroupID,
        seconds,
        unload,
      })
    }
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  private saveTimeInLocalStorage() {
    localStorage.setItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`, JSON.stringify({
      seconds: Math.floor(this.player.video.currentTime),
      ts: Date.now(),
    }))
  }
}
