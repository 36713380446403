







































































































































import { sortBy } from 'lodash'
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'

import DPlayerVideoPlayer from '@/components/DPlayerVideoPlayer.vue'
import DefaultVideoPlayer from '@/components/DefaultVideoPlayer.vue'
import LessonStatusCard from '@/components/cards/LessonStatusCard.vue'
import LessonVideoTimecodeItem from '@/components/views/lesson/LessonVideoTimecodeItem.vue'
import RateLessonModal from '@/components/modals/lessons/RateLessonModal.vue'
import LessonRateStatus from '@/components/views/lesson/LessonRateStatus.vue'
import LessonCheckedStatus from '@/components/views/lesson/LessonCheckedStatus.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserCard from '@/components/cards/UserCard.vue'
import VideoCustom from '@/components/VideoCustom.vue'
import YoutubeIframe from '@/components/YoutubeIframe.vue'
import YoutubeIframeNative from '@/components/YoutubeIframeNative.vue'
import MasterEducationModule from '@/store/modules/master/education'
import MasterLessonsModule from '@/store/modules/master/lessons'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import {
  LessonLargeResource,
  MasterLessonRateRequest,
} from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import { parseDateToMilliseconds } from '@/utils/functions'
import { lessonDuration } from'@/utils/constants'

// import MasterProgressModule from '@/store/modules/master/progress'

@Component({
  components: {
    DPlayerVideoPlayer,
    DefaultVideoPlayer,
    LessonCheckedStatus,
    LessonRateStatus,
    LessonStatusCard,
    LessonVideoTimecodeItem,
    RateLessonModal,
    Tag,
    UserCard,
    VideoCustom,
    YoutubeIframe,
    YoutubeIframeNative,
  },
})
export default class LessonVideoCurtain extends Mixins(MasterGroupMixin, PermissionsMixin) {
  private videoRef!: HTMLVideoElement
  private videoDuration = 0

  @Prop({ default: null })
  private lesson!: LessonLargeResource

  @Prop({ default: null })
  private rating!: number
  private ratingInner: number | null = null

  @PropSync('lessonCheckedStatus', { default: false })
  private isLessonChecked!: boolean

  @Prop({ default: null })
  private saveTimecode!: number | null

  private isLessonEnded = false
  private showRateModal = false

  private get basePayload() {
    return {
      lessonID: this.lesson.id,
      masterGroupID: this.masterGroupID,
    }
  }

  private basicBroadcastDuration = (lessonDuration || 3) * 3600 // часы в секунды

  private tab = 0

  private get timeToStartBroadcast() {
    return parseDateToMilliseconds(this.lesson.spendingAt)
  }

  private get isBroadcastUrlYoutube() {
    const url = this.lesson.broadcastUrl

    if (url && this.isBroadcastLive) {
      return url.includes('youtu')
    }

    return false
  }

  private get isBroadcastUrlVK() {
    const url = this.lesson.broadcastUrl

    if (url && this.isBroadcastLive) {
      return !!url.match(/vk.com|vkvideo.ru/ig)?.length
    }
    return false
  }

  // Note: В случае зума никакой проверки для ссылки не используется,
  // просто смотрим - идёт ли трансляция
  private get isBroadcastUrlZoom() {
    return this.isBroadcastLive
  }

  private get broadcastUrl() {
    const url = this.lesson.broadcastUrl
    const ytRegex = new RegExp(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/live\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^&/]{11})/i)
    const vkRegex = new RegExp(/\d+_\d+(\?hash=[0-9a-f]+)?/i)
    if (url) {
      const isZoom = url.includes('zoom.us')
      const isYoutube = url.includes('youtu')
      const isVK = !!url.match(/vk.com|vkvideo.ru/ig)?.length
      if (isZoom) {
        const zoomId = url.split('/').pop()?.split('?').shift()
        return `https://zoom.us/wc/join/${zoomId}`
      }
      if (isYoutube) {
        const match = url.match(ytRegex)
        return match && match[1] ? `https://www.youtube-nocookie.com/embed/${match[1]}` : ''
      }
      if (isVK) {
        const match = url.match(vkRegex)
        if (match) {
          const link = match[0].toString().replace('?hash=', '_').split('_')
          return `https://vk.com/video_ext.php?oid=-${link[0]}&id=${link[1]}${link[2] ? `&hash=${link[2]}` : ''}&hd=2&autoplay=1`
        }
        return ''
      }
    }
    return ''
  }

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get isLessonStarted() {
    return this.lesson.ignorePubDate ? parseDateToMilliseconds(this.lesson.startPublishedAt) <= this.currentTime : parseDateToMilliseconds(this.lesson.spendingAt) <= this.currentTime && parseDateToMilliseconds(this.lesson.startPublishedAt) <= this.currentTime
  }

  private mounted() {
    this.ratingInner = this.rating
  }

  private get isBroadcastLive() {
    return this.lesson.broadcastUrl && (this.timeToStartBroadcast <= this.currentTime && this.currentTime <= this.timeToStartBroadcast + this.basicBroadcastDuration * 1000)
  }

  private get isVideoAvailable () {
    return (this.isLessonStarted && Boolean(this.lesson.video || this.lesson.videoUrl))
  }

  private get videoUrlIsVK() {
    return !!this.lesson.videoUrl?.match(/vk.com|vkvideo.ru/ig)?.length
  }

  private get sortedTimecodes() {
    return sortBy(this.lesson.timecodes, ['second'])
  }

  private handleVideoInitialized(videoRef: HTMLVideoElement) {
    this.videoRef = videoRef
  }

  private readymetadata(duration: number) {
    this.videoDuration = duration
  }

  private handleJumpToTimeCode (seconds: number) {
    if (this.videoRef) {
      this.videoRef.play()
      this.videoRef.currentTime = seconds
    } else {
      this.$bus.$emit('jumpToTimeCode', seconds)
    }
  }

  private get masterGroupID(){
    return MasterEducationModule.currentMasterGroupID
  }

  private get days() {
    const inMili = this.timeToStartBroadcast
    const secondsTill = (inMili - this.currentTime) / 1000
    return Math.floor(secondsTill / 86400)
  }

  private get hours() {
    const inMili = this.timeToStartBroadcast
    const secondsTill = (inMili - this.currentTime) / 1000

    this.checkIfLessonHasEnded(secondsTill)

    return Math.floor((secondsTill - this.days * 86400) / 3600)
  }

  private get minutes() {
    const inMili = this.timeToStartBroadcast
    const secondsTill = (inMili - this.currentTime) / 1000

    this.checkIfLessonHasEnded(secondsTill)

    return Math.floor((secondsTill - this.days * 86400 * 60) / 3600 * 60)
  }

  private checkIfLessonHasEnded(secondsTill: number) {
    this.isLessonEnded = secondsTill < 0
  }

  private handleWatchedLesson() {
    MasterLessonsModule.checkLesson(this.basePayload)
      .then(() => {
        this.isLessonChecked = true
        this.fetchLessons()
      })
      .catch(this.notifyError)
  }

  private fetchLessons() {
    MasterLessonsModule.fetchLessons({ masterGroupID: this.currentMasterGroupID, params: MasterLessonsModule.lessonsFilter })
      .catch(this.notifyError)
  }

  // Запрос на поверку получения нового уровня
  // private checkUpdates (tick: number) {
  //   const timeout: number = tick === 1 ? 3000 : tick === 2 ? 4000 : 5000
  //   setTimeout(() => {
  //     MasterProgressModule.checkUpdates()
  //       .then((userUpd) => {
  //         if (userUpd.length) {
  //           this.$bus.$emit('new-level', userUpd[userUpd.length - 1])
  //         } else if (tick !== 3) {
  //           this.checkUpdates(tick + 1)
  //         }
  //       })
  //   }, timeout)
  // }

  private handleRating(ratingData: MasterLessonRateRequest) {
    this.ratingInner = ratingData.rating as number
    MasterLessonsModule.rateLesson({ ...this.basePayload, params: ratingData })
      .then(() => {
        this.notifyInfo('Ваша оценка отправлена')
        this.fetchLessons()
      })
      .catch(this.notifyError)
  }

  private changeTabs(value: number) {
    if (value === 2) {
      this.$nextTick(() => {
        (this.$refs.defaultPlayer as HTMLElement).oncontextmenu = () => false
      })
    }
  }

  @Watch('lesson')
  private watchLesson() {
    this.ratingInner = this.rating
  }
}
