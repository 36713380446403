










import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class YoutubeIframe extends Vue {
  @Ref() youtube!: any

  @Prop({ required: true })
  private src!: string

  private get videoID() {
    const match = this.src.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/live\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^&/]{11})/i)
    return match && match[1] ? match[1] : ''
  }

  private mainEl: HTMLDivElement | null = null

  private mounted() {
    this.mainEl = document.querySelector('.v-main')
    const backBtn = document.createElement('button')
    backBtn.classList.add('plyr__btn', 'plyr__btn_back')
    backBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 976q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120 616h80q0 117 81.5 198.5T480 896q117 0 198.5-81.5T760 616q0-117-81.5-198.5T480 336h-6l62 62-56 58-160-160 160-160 56 58-62 62h6q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840 616q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480 976ZM360 736V556h-60v-60h120v240h-60Zm140 0q-17 0-28.5-11.5T460 696V536q0-17 11.5-28.5T500 496h80q17 0 28.5 11.5T620 536v160q0 17-11.5 28.5T580 736h-80Zm20-60h40V556h-40v120Z"/></svg>'

    const forwardBtn = document.createElement('button')
    forwardBtn.classList.add('plyr__btn', 'plyr__btn_forward')
    forwardBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M360 736V556h-60v-60h120v240h-60Zm140 0q-17 0-28.5-11.5T460 696V536q0-17 11.5-28.5T500 496h80q17 0 28.5 11.5T620 536v160q0 17-11.5 28.5T580 736h-80Zm20-60h40V556h-40v120Zm-40 300q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120 616q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480 256h6l-62-62 56-58 160 160-160 160-56-58 62-62h-6q-117 0-198.5 81.5T200 616q0 117 81.5 198.5T480 896q117 0 198.5-81.5T760 616h80q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480 976Z"/></svg>'

    backBtn.addEventListener('dblclick', (e) => {
      e.stopPropagation()
      backBtn.classList.add('plyr__btn_visible')
      this.youtube.player.rewind(10)
      setTimeout(() => {
        backBtn.classList.remove('plyr__btn_visible')
      }, 1000)
    })
    forwardBtn.addEventListener('dblclick', (e) => {
      e.stopPropagation()
      forwardBtn.classList.add('plyr__btn_visible')
      this.youtube.player.forward(10)
      setTimeout(() => {
        forwardBtn.classList.remove('plyr__btn_visible')
      }, 1000)
    })

    this.youtube.player.on('enterfullscreen', () => {
      if (this.mainEl) {
        this.mainEl.style.position = 'relative'
        this.mainEl.style.zIndex = '10'
      }
    })

    this.youtube.player.on('exitfullscreen', () => {
      if (this.mainEl) {
        this.mainEl.style.position = ''
        this.mainEl.style.zIndex = ''
      }
    })

    this.youtube.player.on('ready', (e: any) => {
      this.$emit('readymetadata', e.detail.plyr.embed.getDuration())
      this.youtube.$el.insertAdjacentElement('beforeend', backBtn)
      this.youtube.$el.insertAdjacentElement('beforeend', forwardBtn)
    })
    this.$bus.$on('jumpToTimeCode', this.handleJumpToTimeCode)
  }

  private destroyed() {
    this.$bus.$off('jumpToTimeCode', this.handleJumpToTimeCode as any)
  }

  private handleJumpToTimeCode (seconds: number) {
    this.youtube.player.play()
    this.youtube.player.currentTime = seconds
  }
}
