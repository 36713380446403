






import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// interfaces
import { LessonTimecodeShortResource } from '@/store/types'

@Component
export default class LessonVideoTimecodeItem extends Vue {
  @Prop({ required: true })
  private timecode!: LessonTimecodeShortResource

  @Prop({ default: false })
  private readonly!: boolean

  private secondsToTime (seconds: number) {
    const fulltime = new Date(seconds * 1000).toISOString().substr(11, 8)
    return fulltime.indexOf('00:') === 0 ? fulltime.substr(3, 5) : fulltime
  }

  private handleTimeCodeClick(readonly: boolean) {
    if (readonly) return
    // fix sentry issue:
    // https://sn.atwinta.online/organizations/atwinta/issues/37074/?project=22&query=&referrer=project-issue-stream
    this.$emit('click', this.timecode ? this.timecode.second : 0)
  }

}
