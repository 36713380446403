












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LessonRateStatus extends Vue {
  @Prop({ default: true })
  private visible!: boolean

  @Prop({ default: false })
  private isRated!: boolean
}
