












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LessonCheckedStatus extends Vue {
  @Prop({ default: false })
  private isChecked!: boolean
}
