























































import { Component, Prop } from 'vue-property-decorator'

// components
import FilesList from '@/components/FilesList.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// interfaces
import { AdjacentLessonResource, LessonLargeResource, ManagerLessonLargeResource } from '@/store/types'
import LabelDataRow from '@/components/LabelDataRow.vue'
import MasterWithReviewCard from '@/components/cards/MasterWithReviewCard.vue'
import RatedLessonModal from '@/components/modals/lessons/RatedLessonModal.vue'
import SystemModule from '@/store/modules/system'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    FilesList,
    LabelDataRow,
    MasterWithReviewCard,
    RatedLessonModal,
  },
})
export default class LessonCommonView extends NotifyMixin {
  @Prop({ default: null })
  private lesson!: ManagerLessonLargeResource & LessonLargeResource

  @Prop({ default: null })
  private nextLesson!: AdjacentLessonResource | null

  @Prop({ default: null })
  private prevLesson!: AdjacentLessonResource | null

  private showModalRated = false
  private currentMaster: ManagerLessonLargeResource | null = null
  private expandedIndex = 1

  private get isMaster() {
    return SystemModule.interface === 'master'
  }

  private get mastersWithReview() {
    if (this.lesson.masters) {
      return this.lesson.masters.filter(item => item.rating !== null)
    }
    return []
  }

  private get groupID() {
    return this.$route.params.groupID
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handleRatedModal(master: ManagerLessonLargeResource) {
    this.currentMaster = master
    this.showModalRated = true
  }
}
