








import { Component, Prop, Vue } from 'vue-property-decorator'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import { ManagerLessonLargeMasterResource } from '@/store/types'

@Component({
  components: {
    ButtonArrow,
  },
})
export default class MasterWithReviewCard extends Vue {
  @Prop({ required: true })
  private master!: ManagerLessonLargeMasterResource

  private handleReview() {
    this.$emit('rating-modal', this.master)
  }
}
