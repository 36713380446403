














import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Hls from 'hls.js'

import { MediaResource } from '@/store/types'
import MasterLessonsModule from '@/store/modules/master/lessons'

@Component
export default class DefaultVideoPlayer extends Vue {
  @Ref() defaultPlayer!: HTMLVideoElement

  @Prop({ required: true })
  private video!: MediaResource

  @Prop({ default: null })
  private saveTimecode!: number | null

  @Prop({ required: true })
  private lessonID!: number

  @Prop({ required: true })
  private masterGroupID!: number

  private interval: number | null = null

  private method = this.saveTimeLesson.bind(this, true)

  private checkIsIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  private mounted() {
    if (this.video.hls && this.defaultPlayer && Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(this.video.hls)
      hls.attachMedia(this.defaultPlayer)
    }
    window.addEventListener('beforeunload', this.method)
  }

  private beforeDestroy() {
    this.saveTimeLesson()
    window.removeEventListener('beforeunload', this.method)
  }

  private handleLoadedMetaData(e: any) {
    if (this.saveTimecode !== null && !this.checkIsIOS) {
      e.target.currentTime = this.saveTimecode
    }
    this.$emit('readymetadata', e.target.duration)
  }

  private handleCanplay(e: any) {
    if (this.saveTimecode !== null && this.checkIsIOS) {
      e.target.currentTime = this.saveTimecode
    }
    this.$emit('videoInitialized', e.target)
  }

  // вызывается при паузе на плеере или событии beforeUnload
  private saveTimeLesson(unload = false) {
    this.clearInterval()
    const seconds = Math.floor(this.defaultPlayer?.currentTime)
    if (typeof seconds === 'number' && seconds >= 0) {
      if (this.saveTimecode !== seconds && Math.floor(this.defaultPlayer?.duration) !== seconds) {
        MasterLessonsModule.saveTimecodeLesson({
          lessonID: this.lessonID,
          masterGroupID: this.masterGroupID,
          seconds,
          unload,
        })
      }
    }
  }

  private handleEnded() {
    this.clearInterval()
    MasterLessonsModule.saveTimecodeLesson({
      lessonID: this.lessonID,
      masterGroupID: this.masterGroupID,
      seconds: 0,
      unload: false,
    })
    localStorage.removeItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`)
  }

  private handlePlay() {
    this.interval = setInterval(this.saveTimeInLocalStorage, 5000)
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  private saveTimeInLocalStorage() {
    localStorage.setItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`, JSON.stringify({
      seconds: Math.floor(this.defaultPlayer.currentTime),
      ts: Date.now(),
    }))
  }
}
